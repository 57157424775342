import React from 'react';
import styled from 'styled-components';

import { Layout } from '@components/Layout';
import { Bold } from '@components/Text';
import { BannerBlog } from '@components/Blocks';
import { Column } from '@lib/nvstr-common-ui.es';
import { HomeHeroBackground } from '@components/Backgrounds';
import {
  ConversationsFeature,
  NewsFeature,
  OptimizePortfolioFeature,
  Pricing,
  CashPromo,
  FeatureOverview,
  Hero,
  PressMentions,
} from '@components/Content';
import { PATHS } from '@constants/paths';
import { TrackingEvents } from '../tracking';

import ApplicationMessage from '@components/UI/ApplicationMessage';

import { parseQueryString } from 'utils/usefulFuncs';
import { storeSessionParams } from '@utils/services';
import { getBaseUrl } from '@utils/network';

const Wrapper = styled.div`
  overflow: hidden;

  .section-container {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (max-width: 1200px) {
    .section-container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media (max-width: 999px) {
    .section-container {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media (max-width: 600px) {
    .section-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (min-width: 650px) and (max-width: 999px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.4em;
      line-height: 110%;
    }
  }

  @media (max-width: 366px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const SessionMessageContainer = styled.div`
  position: fixed;
  height: 100px;
  top: 135px;
  left: 0;
  right: 0;
  z-index: 20;
`;

const Home = () => {
  const [sessionMessage, setSessionMessage] = React.useState(null);

  React.useEffect(() => {
    const properties = {};
    TrackingEvents.splash.viewHomepage.send(properties);
  }, []);

  React.useEffect(() => {
    const handleAuthParams = (query) => {
      if (query.loginRequired || query.sessionExpired) {
        const message = query.loginRequired ? (
          <>
            Please{' '}
            <a href={PATHS.SIGN_IN} style={{ textDecoration: 'none' }}>
              <Bold>log in</Bold>
            </a>{' '}
            to continue
          </>
        ) : (
          <>
            You were logged out due to inactivity. Please{' '}
            <a href={PATHS.SIGN_IN} style={{ textDecoration: 'none' }}>
              <Bold>log in</Bold>
            </a>{' '}
            to continue.
          </>
        );
        setSessionMessage(message);
      }
    };

    const handleRefererParams = () => {
      const BASEURL = getBaseUrl();
      const {
        location: { search },
      } = window;

      if (search !== '') {
        const url = BASEURL + search;
        storeSessionParams(url);
      }
    };

    const query = parseQueryString(window.location.search);
    handleAuthParams(query);
    handleRefererParams();
  }, []);

  return (
    <Layout pageTitle={'Tornado'} section="home" banner={<BannerBlog />} background={<HomeHeroBackground />}>
      <Wrapper>
        {sessionMessage !== null && (
          <SessionMessageContainer>
            <Column hAlign="center">
              <ApplicationMessage message={sessionMessage} />
            </Column>
          </SessionMessageContainer>
        )}
        <div style={{ position: 'relative', zIndex: 3 }}>
          <Hero />
          <div style={{ position: 'relative', zIndex: 1 }}>
            <PressMentions />
          </div>
          <FeatureOverview />
          <ConversationsFeature />
          <NewsFeature />
          <OptimizePortfolioFeature />
          <CashPromo />
          <Pricing />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Home;
