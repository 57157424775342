import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: ${(props) => props.width - 1}px;

  svg {
    position: relative;
    top: 0;
    line-height: 1;
  }
  g {
    fill: ${(props) =>
      props.isDarkMode
        ? props.theme.secondary.white
        : props.theme.primary.charcoal};
  }
`;

const Close = ({ isDarkMode, width }) => {
  return (
    <Wrapper isDarkMode={isDarkMode} width={width}>
      <svg
        width={width}
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M12.3327 1.34163L11.1577 0.166626L6.49935 4.82496L1.84102 0.166626L0.666016 1.34163L5.32435 5.99996L0.666016 10.6583L1.84102 11.8333L6.49935 7.17496L11.1577 11.8333L12.3327 10.6583L7.67435 5.99996L12.3327 1.34163Z" />
        </g>
      </svg>
    </Wrapper>
  );
};

export default Close;
